/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import './src/global/index.scss';
import './src/global/style.scss';
import 'animate.css';
import 'react-photoswipe-2/lib/photoswipe.css';
import 'uikit/dist/css/uikit.min.css';
import UIKit from 'uikit/dist/js/uikit.min.js';
import icons from 'uikit/dist/js/uikit-icons.min.js';
import wrapWithProvider from './wrap-with-provider';

if (typeof window !== 'undefined') {
  UIKit.use(icons);
}

export const wrapRootElement = wrapWithProvider;
