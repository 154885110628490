import produce from 'immer';
import { TYPES } from './types';
import { MAX_PROD_QUANTITY, MIN_PROD_QUANTITY } from '../utils/constants';

// Sample item object
// const item = {
//   id: '',
//   name: '',
//   unit_price: 0,
//   top_image_url: '',
//   url: '',
//   quantity: 0,
// };

export const initialState = {
  cart: [],
  orderDetail: {
    fullName: '',
    email: '',
    address: '',
    city: '',
    district: '',
    area: '',
    phone: '',
  },
};

export default (state, action) => {
  const { type, payload } = action;

  if (type === TYPES.ADD_ITEM) {
    const nextState = produce(state, (draft) => {
      const targetProduct = draft.cart.find((product) => product.id === payload.id);
      if (targetProduct) {
        const nextQuantity = (targetProduct['quantity'] += 1);
        if (nextQuantity <= MAX_PROD_QUANTITY) {
          targetProduct.quantity = nextQuantity;
        }
        return;
      }
      payload['quantity'] = 1;
      draft.cart.push(payload);
    });
    return nextState;
  }

  if (type === TYPES.REMOVE_ITEM) {
    const nextState = produce(state, (draft) => {
      const targetProductIndex = draft.cart.findIndex((product) => product.id === payload);
      if (targetProductIndex !== -1) {
        draft.cart.splice(targetProductIndex, 1);
      }
    });
    return nextState;
  }

  if (type === TYPES.CLEAR_CART) {
    return initialState;
  }

  if (type === TYPES.ADJUST_QUANTITY) {
    const { itemId, quantity } = payload;
    const nextState = produce(state, (draft) => {
      const targetProduct = draft.cart.find((product) => product.id === itemId);
      if (targetProduct) {
        targetProduct.quantity = quantity;
      }
    });
    return nextState;
  }

  if (type === TYPES.INCREASE_QUANTITY_BY_ONE) {
    const nextState = produce(state, ({ cart: draftCart }) => {
      const targetProduct = draftCart.find((product) => product.id === payload);
      if (targetProduct) {
        const nextQuantity = targetProduct.quantity + 1;
        if (nextQuantity <= MAX_PROD_QUANTITY) {
          targetProduct.quantity = nextQuantity;
        }
      }
    });
    return nextState;
  }

  if (type === TYPES.DECREASE_QUANTITY_BY_ONE) {
    const nextState = produce(state, ({ cart: draftCart }) => {
      const targetProduct = draftCart.find((product) => product.id === payload);
      if (targetProduct) {
        const nextQuantity = targetProduct.quantity - 1;
        if (nextQuantity >= MIN_PROD_QUANTITY) {
          targetProduct.quantity = nextQuantity;
        }
      }
    });
    return nextState;
  }

  return state;
};
