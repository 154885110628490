export const MAX_SMALL_SCREEN_WIDTH = '768px';
export const MAX_WIDTH = '992px';
export const MAX_BIG_SCREEN_WIDTH = '1200px';

export const MAX_PROD_QUANTITY = 99;
export const MIN_PROD_QUANTITY = 1;

export const hostUrl = () => {
  return typeof window === 'object' ? window?.location?.origin : '';
};

export const blogContentTypes = {
  CAROUSEL: 'images',
  IMAGE: 'image',
  YOUTUBE: 'url',
  TEXT: 'paragraph',
};
